import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

export const ToastMessage = ({ message, showToast, setShowToast }) => {
    return (
        <ToastContainer className="p-3" position="top-center">
            <Toast
                autohide
                delay={3000}
                show={showToast}
                onClose={() => setShowToast(false)}
            >
                <Toast.Body
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        fontSize: '16px',
                        background: '#f8f9fa',
                        color: 'hsl(0deg 0% 13%)',
                    }}
                >
                    {message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
