import styled from 'styled-components'

export const BirthCardContainer = styled.div`
    margin: auto;
    max-width: 376px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

export const ProgressContainer = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: #5b5b5b;
    height: 6px;
`

export const Progress = styled.div`
    border-radius: 4px;
    background: #a3a3a3;
    height: 100%;

    transition: width 0.5s;
`

export const IconButton = styled.button`
    border: none;
    background: none;
    width: max-content;
    padding: 0px;
`

export const Title = styled.div`
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
`

export const SubTitle = styled.div`
    color: #adadad;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0.15px;
`

export const Back = styled.div`
    display: grid;
    grid-template-columns: 1fr 7fr 1fr;

    align-items: center;
    width: 100%;

    img {
        margin-right: 20px;
        width: 16px;
        height: 16px;
    }

    span {
        color: #fafafa;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 4px;
    }

    .progress-number {
        color: var(--Neutral-neutral-80, #b9b9b9);

        /* vb/ref/typescale/label-small-regular */
        font-family: 'Readex Pro';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 13.75px */
        letter-spacing: 0.5px;
    }
`

export const Submit = styled.button`
    width: 100%;
    height: 52px;
    display: flex;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-radius: 14px;
    background: var(--vb-ref-color-primary-50, #fad810);

    border: none;

    color: var(--vb-ref-color-primary-0, #000);
    text-align: center;

    /* vb/ref/typescale/title-medium-semibold */
    font-family: 'Readex Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
    cursor: pointer;
`

export const TermsContainer = styled.div`
    color: var(--White-500, #d6d6d6);
    /* vb/ref/typescale/label-large-regular */
    font-family: 'Readex Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.1px;

    display: flex;
    gap: 6px;
    align-items: start;

    input {
        margin-top: 4px;
    }

    a {
        color: #fff;
        /* vb/ref/typescale/label-large-semibold */
        font-family: 'Readex Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
    }
`

export const FormContainer = styled.div`
    align-self: center;
    padding: 0px 16px;
    margin-bottom: 15px;

    .input-group {
        border-radius: 8px;
        border: 1px solid var(--Surface-Disabled, #5b5b5b);
        background: var(--Surface-Elevate-1, #1f1f1f);
        height: 42px;

        border: ${(props) =>
            (props.errors?.username_server_error || props.errors?.username) &&
            '1px solid #FFC0C0'};

        border: ${(props) => props.isValid && '1px solid #ABE7A2'};
    }

    .form-control {
        background-color: transparent;
        height: 98%;

        border: none;
        border-radius: 4px;

        color: var(--Text-Heading---Primary, #fff);

        /* vb/ref/typescale/title-medium-regular */
        font-family: 'Readex Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.15px;
    }

    .form-control:focus {
        box-shadow: none;
        color: white;
    }

    .form-control::placeholder {
        color: var(--Text-Heading---Tertiary, #949494);

        /* vb/ref/typescale/title-medium-regular */
        font-family: 'Readex Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.15px;
    }

    .form-text {
        margin-left: 12px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.04em;
        color: #707070;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .form-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: white;
    }

    .form-check-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: white;
    }

    .form-check:nth-child(2) {
        margin-left: 20px;
    }

    .file-input-hide {
        opacity: 0;
        width: 1px;
        height: 1px;
    }

    .form-check-input:checked {
        background-color: #f9cf5f;
        border-color: #f9cf5f;
    }

    .form-check-input[type='checkbox']:indeterminate {
        background-color: #f9cf5f;
        border-color: #f9cf5f;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
    }

    .dropdown-toggle {
        background: none;
        border: none;
        width: 100%;
        padding: 0px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        width: 100%;
        background: #303030;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .dropdown-item {
        color: white;
        padding: 10px;
    }

    .dropdown-item:hover {
        background: #373737;
    }

    .dropdown-item.active {
        background: #373737;
    }

    .dropdown-item.active mark {
        background: none;
        color: white;
    }

    .dropdown-item mark {
        background: none;
        color: white;
    }

    .dropdown-item .rbt-highlight-text {
        padding: 0px;
    }

    .mb-4 {
        margin-bottom: 0px !important;
    }

    .registration-error {
        color: #ffa9a9;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.5px;

        display: flex;
        margin-top: 7px;
    }

    .registration-success {
        color: var(--Colors-Green-50, #82d076);

        /* vb/ref/typescale/label-medium-regular */
        font-family: 'Readex Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15px */
        letter-spacing: 0.5px;
    }

    .input-title {
        color: var(--Text-Heading---Tertiary, #949494);

        /* vb/ref/typescale/label-medium-regular */
        font-family: 'Readex Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15px */
        letter-spacing: 0.5px;
    }

    width: 100%;

    @media (min-width: 536px) {
        width: 536px;
    }
`

export const ErrorMessage = styled.div`
    color: var(--Colors-Red-70, #ffa9a9);
    font-family: 'Readex Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
    letter-spacing: 0.5px;
    width: 100%;
    margin-top: -18px;
    margin-left: 25px;
}
`

export const Timer = styled.div`
    display: flex;
    gap: 8px;

    span {
        color: var(--White-500, #d6d6d6);

        /* vb/ref/typescale/title-small-light */
        font-family: 'Readex Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 125%; /* 17.5px */
        letter-spacing: 0.1px;
    }

    .time-count {
        color: var(--White-100, #fff);

        /* vb/ref/typescale/label-medium-semibold */
        font-family: 'Readex Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 15px */
        letter-spacing: 0.5px;
    }
`

export const MobileNumber = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 10px;
}

    button {
        display: flex;
        height: 40px;
        padding: 9px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 24px;
        border: 2px solid var(--vb-ref-color-primary-20, #463d0a);

        color: var(--vb-ref-color-primary-50, #fad810);
        text-align: center;

        font-family: 'Readex Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 17.5px */
        letter-spacing: 0.1px;

        background: transparent;
    }

    span {
        color: rgba(255, 255, 255, 0.8);

        /* vb/ref/typescale/body-large-semibold */
        font-family: 'Readex Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 25.6px */
        letter-spacing: 0.15px;
    }
`

export const OtpErrorMessage = styled.div`
    color: var(--vb-sys-color-error50, var(--Error-error-50, #ff7e7e));

    /* vb/ref/typescale/label-small-light */
    font-family: 'Readex Pro';
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 125%; /* 13.75px */
    letter-spacing: 0.5px;

    margin-top: -16px;
    width: 83%;
`

export const ResendOtp = styled.button`
    border: none;
    background: none;

    color: var(--vb-ref-color-primary-50, #fad810);
    text-align: center;

    /* vb/ref/typescale/title-small-semibold */
    font-family: 'Readex Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.1px;
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;

    @media (max-width: 500px) {
        height: 70px;
    }
`

export const SelectBeforContainer = styled.div`
    display: flex;
    gap: 7px;
    color: white;
`

export const CountryCodeBlock = styled.div`
    border-left: 1px solid #ffffff42;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-left: 10px;
    height: 40px;
    margin: -9px 0px;
    font-family: Readex Pro;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
`
